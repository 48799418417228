import React, { useMemo, CSSProperties } from 'react';
import { makeStyles, CardActions, IconButton, Menu, MenuItem } from '@material-ui/core';
import AssetCardAction, { Action, ASSET_CARD_ACTION_SPACING } from './AssetCardAction';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { colors } from '../../../styles/theme';
import clsx from 'clsx';

const ESTIMATED_LETTER_WIDTH = 10;
const ESTIMATED_ICON_SIZE = 16;

interface AssetCardProps {
  actions: Action[];
  style?: CSSProperties;
  className?: string;
}

const AssetCardActionsRow = (props: AssetCardProps) => {
  const { actions } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const sizes = useMemo(
    () =>
      actions.map(
        (action) =>
          (action.label?.length ?? 0) * ESTIMATED_LETTER_WIDTH + ASSET_CARD_ACTION_SPACING + ESTIMATED_ICON_SIZE
      ),
    [actions]
  );

  const summedSizesToElements = useMemo(
    () =>
      sizes.reduce<{ sizes: number[]; currentSize: number }>(
        (acc, size) => ({
          currentSize: acc.currentSize + size,
          sizes: [...acc.sizes, acc.currentSize + size],
        }),
        { sizes: [], currentSize: 0 }
      ),
    [sizes]
  );

  const numberOfVisibleElements = useMemo(() => {
    const firstBiggerIndex = summedSizesToElements.sizes.findIndex((size) => size > 470);
    return firstBiggerIndex === -1 ? sizes.length : firstBiggerIndex;
  }, [sizes, summedSizesToElements]);

  const areAnyActionsHidden = useMemo(() => numberOfVisibleElements < sizes.length, [numberOfVisibleElements, sizes]);

  const injectCloseMenu = (action: Action) => {
    return {
      ...action,
      onClick: () => {
        closeMenu();
        if (action.onClick) {
          action.onClick();
        }
      },
    };
  };

  return (
    <CardActions classes={{ root: classes.actions }} style={props.style} className={props.className}>
      <div className={classes.actionsButtons}>
        {props.actions.slice(0, numberOfVisibleElements).map((action) => (
          <AssetCardAction key={action.label} action={action} />
        ))}
      </div>
      {areAnyActionsHidden && (
        <IconButton className={classes.moreButton} onClick={openMenu}>
          <MoreVertIcon className={classes.moreButtonIcon} />
        </IconButton>
      )}
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={closeMenu}>
        {props.actions.slice(numberOfVisibleElements).map((action) => (
          <MenuItem
            key={action.label}
            onClick={action.disabled ? undefined : injectCloseMenu(action).onClick}
            className={clsx(action.disabled && classes.disabledMenuItem)}
            disableRipple={action.disabled}
          >
            <AssetCardAction action={injectCloseMenu(action)} transparent />
          </MenuItem>
        ))}
      </Menu>
    </CardActions>
  );
};

const useStyles = makeStyles(() => ({
  actions: {
    padding: '0px 0px',
    borderTop: `solid 1px ${colors.grayLine}`,
    justifyContent: 'space-between',
  },
  actionsButtons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '0px',
  },
  disabledMenuItem: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  moreButton: {
    height: '18px',
    width: '18px',
    marginRight: '-6px',
  },
  moreButtonIcon: {
    height: '22px',
  },
}));

export default AssetCardActionsRow;
