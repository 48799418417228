import React, { useContext } from 'react';
import { FormDataMappingContextType } from '@terragotech/form-renderer';
import { useMemo } from 'react';
import { AssetType } from '../contexts/AggregatesContext/types';
import {
  useAuthContext,
  useCurrentLocation,
  useUserInfo,
  useOfflineDataLookup,
  useOfflineProximityLookup,
  useOfflineUserLookup,
} from '@terragotech/gen5-shared-components';
import { NetworkStatusContext } from '../contexts/networkStatusContext';
import { useSelectedProject } from '../contexts/selectedProjectContext';
import { useSelectedLocation } from '../contexts/selectedLocationContext';
/**
 * This hook provides the metadata needed for form validation and conditionals
 * @param target The asset currently being edited
 */
export const useWorkflowDataMapping = (target?: AssetType | AssetType[]): FormDataMappingContextType => {
  const [timeOpened] = React.useState(new Date()); // we collect this data for analytics
  const bestLocation = useCurrentLocation(); // best location is used to determine proximity
  const authContextInfo = useAuthContext();
  const { isOnline } = useContext(NetworkStatusContext);
  const { roles, roleString, permissionString, permissions, username, email, firstName, lastName } = useUserInfo();
  const offlineDataLookup = useOfflineDataLookup();
  const offlineProximityLookup = useOfflineProximityLookup();
  const offlineUserLookup = useOfflineUserLookup();
  const { selectedProjects } = useSelectedProject();
  const { selectedLocation } = useSelectedLocation();

  return useMemo<FormDataMappingContextType>(() => {
    return {
      accessors: {
        ONLINE_ATTRIB_LOOKUP: offlineDataLookup,
        OFFLINE_ATTRIB_LOOKUP: offlineDataLookup, // offline is online for web
        METADATA: () => {
          return {
            isOnline: isOnline,
            timeFormOpened: timeOpened,
            latitude: bestLocation?.latitude,
            longitude: bestLocation?.longitude,
            selectedLatitude: selectedLocation?.lat,
            selectedLongitude: selectedLocation?.lon,
            locationAccuracy: bestLocation?.accuracy,
            userInfo: {
              userName: username,
              email: email,
              familyName: lastName,
              givenName: firstName,
              roles,
              roleString,
              authPayload: authContextInfo,
              authExpiration: authContextInfo && authContextInfo.authExpiration,
              permissionString,
              permissions,
            },
            selectedProjects,
          };
        },
        STATE: () => target || {},
        ONLINE_PROXIMITY_LOOKUP: offlineProximityLookup,
        OFFLINE_PROXIMITY_LOOKUP: offlineProximityLookup, // offline is online for web
        ONLINE_USER_LOOKUP: offlineUserLookup,
        OFFLINE_USER_LOOKUP: offlineUserLookup, // offline is online for web
      },
    };
  }, [
    offlineDataLookup,
    offlineProximityLookup,
    offlineUserLookup,
    isOnline,
    timeOpened,
    bestLocation?.latitude,
    bestLocation?.longitude,
    bestLocation?.accuracy,
    username,
    email,
    lastName,
    firstName,
    roles,
    roleString,
    authContextInfo,
    target,
    permissionString,
    permissions,
  ]);
};
