import { Card, CardContent, makeStyles, Typography, IconButton, Paper, Button } from '@material-ui/core';
import AssetAttributeElement, { AssetAttribute, Asset } from './AssetAttributeElement';
import MiddleCropText from './MiddleCropText';
import AssetCardActionsRow from './AssetCardActionsRow';
import { Action } from './AssetCardAction';
import AssetCardPrimaryAction from './AssetCardPrimaryAction';
import CloseIcon from '@material-ui/icons/Close';
import { convertToCSSTransition } from './MultipleAssetCards';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import { useConfig, colors, graphqlToAssetTransform } from '@terragotech/gen5-shared-components';
import _ from 'lodash';
import PagePage from '../../Page/PagePage';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import moment from 'moment';
import {
  DATETIME_TOKEN_CONVERSION,
  getDateFormat,
  getDateTimeFormat,
  middleTruncate,
} from '@terragotech/gen5-shared-utilities';
import { useMemo } from 'react';

export interface AssetCardProps {
  title: string;
  description?: string;
  attributes: readonly AssetAttribute[];
  asset: Asset | null;
  primaryAction?: Action;
  secondaryActions: Action[];
  contentOpacity?: number;
  onTitleClick?: () => void;
  onCloseClick?: () => void;
  labelValue?: string;
  assetId?: string;
  symbolKey?: string;
  aggregateType?: string;
  onSelect?: (props: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  pageName?: string;
  fullAsset?: MapAssetType;
}

const AssetCard = (props: AssetCardProps) => {
  const classes = useStyles();
  const { aggregateDefinitions, defaultDateTimeFormat } = useConfig();
  const {
    asset,
    labelValue,
    symbolKey,
    mapEditor,
    assetId,
    selectedAggregateId,
    selectableAggregateTypes,
    pageName,
    fullAsset,
  } = props;

  const record = useMemo(
    () =>
      graphqlToAssetTransform({
        flattenRelationships: true,
        result: fullAsset as any,
        aggDef: aggregateDefinitions.find(d => d.queryKey === props.aggregateType),
      }),
    [fullAsset, aggregateDefinitions]
  );

  if (!asset) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography>No asset found</Typography>
        </CardContent>
      </Card>
    );
  }

  if (!props.title && props.secondaryActions.length === 0 && props.attributes.length === 0 && !pageName) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography>No card definition found for this asset type</Typography>
        </CardContent>
      </Card>
    );
  }

  const isSelectableType = () => {
    const definition = aggregateDefinitions.find(d => d.queryKey === props.aggregateType);
    if (_.isEmpty(selectableAggregateTypes)) {
      return true;
    }
    return _.includes(selectableAggregateTypes, definition?.name);
  };

  const isDate = (asset: Asset) => {
    let updatedAsset: Asset = { ...asset };
    const definition = aggregateDefinitions.find(d => d.queryKey === props.aggregateType);
    if (definition?.propertyDefinitions) {
      _.mapKeys(asset, function (value, key) {
        const property = _.find(definition.propertyDefinitions, function (o) {
          return o.label === key || o.field === key;
        });
        if (property && property.type === 'DateTime' && value) {
          let dateValue = value;
          dateValue = dateValue
            ? moment(String(dateValue)).format(
                getDateTimeFormat(
                  defaultDateTimeFormat?.dateFormatType,
                  defaultDateTimeFormat?.dateFormat,
                  defaultDateTimeFormat?.dateSeperator,
                  defaultDateTimeFormat?.timeFormat,
                  { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                )
              )
            : '';
          updatedAsset[key] = dateValue;
        } else if (property && property.type === 'Date' && value) {
          let dateValue = value;
          dateValue = dateValue
            ? moment.utc(String(dateValue))
                .format(
                  getDateFormat(
                    defaultDateTimeFormat?.dateFormatType,
                    defaultDateTimeFormat?.dateFormat,
                    defaultDateTimeFormat?.dateSeperator,
                  )
                )
            : '';
          updatedAsset[key] = dateValue;
        }
      });
    }
    return updatedAsset;
  };
  const truncatedTitle: string = props.title.includes(' ') ? props.title : (middleTruncate(props.title, 20) as string);
  return (
    <Paper elevation={2} className={classes.card}>
      <CardContent className={classes.cardContent} style={{ opacity: props.contentOpacity }}>
        <div className={classes.topRow}>
          <div className={classes.titleGroup}>
            {symbolKey && mapEditor && (
              <img
                width={50}
                height={50}
                src={getSvgImageString(symbolKey, { globalScaleFactor: 1, embedFont: true })}
              />
            )}
            <MiddleCropText
              className={mapEditor ? classes.title : classes.linkTitle}
              onClick={mapEditor ? () => {} : props.onTitleClick}
              label={!mapEditor ? labelValue : ''}
            >
              {truncatedTitle}
            </MiddleCropText>
          </div>
          <IconButton classes={{ root: classes.closeButton }} onClick={props.onCloseClick}>
            <CloseIcon />
          </IconButton>
        </div>
        {props.description && (
          <Typography className={classes.description} component="div">
            {props.description}
          </Typography>
        )}
        {pageName && <PagePage target={record} page={pageName} isAssetCard={true} fabContainerKey='AssetCard'></PagePage>}
        <div className={classes.attributes}>
          {props.attributes
            .filter(x => x.isVisible === true)
            .map(attribute => (
              <AssetAttributeElement key={attribute.key} attribute={attribute} asset={isDate(asset)} />
            ))}
        </div>
        {!mapEditor && props.primaryAction && (
          <div className={classes.primaryActionGroup}>
            <AssetCardPrimaryAction action={props.primaryAction} onClick={props.onCloseClick} />
          </div>
        )}
        {mapEditor && (
          <div className={classes.bottomRow}>
            {isSelectableType() ? (
              <Button
                variant="contained"
                disabled={assetId === selectedAggregateId || !isSelectableType()}
                onClick={() => {
                  props.onSelect &&
                    props.onSelect({
                      assetId,
                      aggregateType: props.aggregateType,
                      label: labelValue,
                    });
                }}
                color="primary"
                style={{ color: '#fff' }}
              >
                {'Select'}
              </Button>
            ) : (
              <Typography className={classes.description} component="div">
                {'Non selectable record'}
              </Typography>
            )}
          </div>
        )}
      </CardContent>
      {!!props.secondaryActions.length && (
        <div className={classes.actionRowGroup}>
          <AssetCardActionsRow
            actions={props.secondaryActions}
            className={classes.actionsRow}
            style={{ opacity: props.contentOpacity }}
          />
        </div>
      )}
    </Paper>
  );
};

const X_BUTTON_SIZE = 48;
const CARD_PADDING = 16;

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 500,
    minWidth: 300,
    width: 'fit-content',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0px',
  },
  cardContent: {
    overflow: 'hidden',
    height: 'auto',
    margin: 0,
    padding: '20px 20px 0px 20px',
    transition: convertToCSSTransition(['opacity'], 0.4),
  },
  actionsRow: {
    transition: convertToCSSTransition(['opacity'], 0.4),
    margin: 0,
    padding: '15px 20px 15px 20px',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  description: {
    fontSize: 15,
    color: colors.grayDescription,
  },
  attributes: {
    marginTop: 5,
  },
  closeButton: {
    margin: 0,
    padding: 0,
    marginTop: -25,
    marginRight: -3,
  },
  bottomRow: {
    height: 'auto',
    paddingTop: 18,
    paddingBottom: 10,
  },
  linkTitle: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '21px',
    cursor: 'pointer',
    maxWidth: 500 - X_BUTTON_SIZE - CARD_PADDING * 2,
  },
  title: {
    color: colors.black,
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '21px',
    cursor: 'pointer',
    maxWidth: 500 - X_BUTTON_SIZE - CARD_PADDING * 2,
  },
  titleGroup: {
    paddingTop: 15,
  },
  primaryActionGroup: {
    paddingTop: 15,
    paddingBottom: 12,
  },
  actionRowGroup: {
    paddingTop: 5,
  },
}));

export default AssetCard;
