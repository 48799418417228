import { makeStyles, Typography } from '@material-ui/core';

export interface AssetAttribute {
  key: string;
  title: string;
  color?: string;
  isVisible?: boolean;
}

export type Asset = Record<string, string>;

interface AssetAttributeElementProps {
  attribute: AssetAttribute;
  asset: Asset;
}

const AssetAttributeElement = (props: AssetAttributeElementProps) => {
  const classes = useStyles();
  const dataValue = props.asset[props.attribute.key];
  return props.attribute.isVisible === true ? (
    <div className={classes.container}>
      <Typography className={classes.value} component="div" style={{ color: props.attribute.color }}>
        <p className={classes.para}>
          <span className={classes.label}>{props.attribute.title}:</span>
          <span style={!dataValue && props.attribute.color === '#1D1D1F' ? { color: '#AAAAAA' } : undefined}>
            {dataValue ? dataValue : '(None)'}
          </span>
        </p>
      </Typography>
    </div>
  ) : null;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 3,
    minHeight: 16,
  },
  label: {
    fontWeight: 'normal',
    fontSize: 14,
    marginRight: 2,
    fontFamily: 'Roboto',
    whiteSpace: 'nowrap',
    '& div': {
      lineHeight: '13px',
    },
  },
  value: {
    wordWrap: 'break-word',
    lineHeight: '12px',
    fontSize: 14,
    fontWeight: 300,
  },
  para: {
    textIndent: -10,
    margin: 0,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Roboto',
  },
}));

export default AssetAttributeElement;
