import { gql } from '@apollo/client';
import { Project } from './types';

export const QUERY = gql`
  {
    roles {
      aggregateTypePermissions {
        aggregateDefinition {
          name
        }
        projectPermissions {
          inclusionType
          projectIds
        }
      }
      name
      id
      dashboards
    }
    projects {
      id
      title
    }
    webUIConfig {
      aggregateDefinitions {
        singular
        plural
        name
      }
    }
  }
`;

export interface QueryResult {
  roles: readonly {
    id: number;
    name: string;
    aggregateTypePermissions: Array<{
      aggregateDefinition: {
        name: string;
        singular: string;
        plural: string;
      };
      projectPermissions: {
        inclusionType: 'include' | 'exclude';
        projectIds: string[];
      };
    }>;
    dashboards: string[];
  }[];
  projects: readonly Project[];
  webUIConfig: {
    aggregateDefinitions: { singular: string; name: string }[];
  };
}

export interface AddRoleVariables {
  roleName: string;
  recordTypesNames: Array<string>;
  dashboards?: Array<string>;
}

export interface AddRoleResponse {
  createRole: {
    id: number;
  };
}

export const ADD_ROLE = gql`
  mutation CreateRole($roleName: String!, $recordTypesNames: [String!]!, $dashboards: [String]) {
    createRole(roleName: $roleName, recordTypesNames: $recordTypesNames, dashboards: $dashboards) {
      id
    }
  }
`;

export interface DeleteRoleVariables {
  roleId: number;
}

export interface DeleteRoleResponse {
  deleteRole: {
    status: string;
  };
}

export const DELETE_ROLE = gql`
  mutation DeleteRole($roleId: Int!) {
    deleteRole(roleId: $roleId) {
      status
    }
  }
`;

export interface UpdateRoleVariables {
  roleId: number;
  roleName: string;
  recordPermissionsIds?: never[];
  dashboards?: string[];
}
export interface UpdateRoleResponse {
  updateRole: {
    status: string;
  };
}

export const UPDATE_ROLE = gql`
  mutation UpdateRole($roleId: Int!, $roleName: String!, $recordPermissionsIds: [String], $dashboards: [String]) {
    updateRole(roleId: $roleId, roleName: $roleName, recordPermissionsIds: $recordPermissionsIds, dashboards: $dashboards) {
      status
    }
  }
`;

export interface SetMultiPermissionsVariables {
  roleUpdates: Array<{ inclusionType: 'exclude' | 'include'; projectIds: string[]; type: string; roleId: number }>;
}

export interface SetMultiPermissionsResponse {
  setMultipleRolesAggregateTypeProjectPermissions: {
    status: string;
  };
}
export const SET_PERMISSIONS_FOR_MULTIPLE_ROLES = gql`
  mutation SetMultiProjectPermissions($roleUpdates: [RoleUpdate]!) {
    setMultipleRolesAggregateTypeProjectPermissions(roleUpdate: $roleUpdates) {
      status
    }
  }
`;
