import { PageControlProvider, useConfig } from '@terragotech/gen5-shared-components';
import {
  initPageRootRenderer,
  NonRepeatableFieldProps,
  V2MapComponent,
  V2StreetViewComponent,
} from '@terragotech/page-renderer';
import React, { useContext, useEffect } from 'react';
import { AssetType } from '../../contexts/AggregatesContext/types';
import { FormParentValueContext } from '../../contexts/formParentValueContext';
import { useWorkflowDataMapping } from '../../hooks/useWorkflowDataMapping';
import TGPageMapField, { TGMapFieldProps } from './TGMapField';
import TGPageStreetViewField, { TGStreetViewFieldProps } from './TGStreetViewField';
import PageFABContainer from '../../pages/PageFABContainer';
import { useInfoContext } from '../../contexts/InfoContext/infoContext';
import { SelectedLocationProvider } from '../../contexts/selectedLocationContext';
import { ActionsExecutorProvider } from '../../hooks/useActionsExecutor';

const useFilterMapFieldProps = (props: NonRepeatableFieldProps<V2MapComponent>, type?: string) => {
  const { value, controlDefinition, readOnly } = props;
  const {
    label,
    info,
    oneLine,
    aggregates,
    height,
    symbols,
    includeWMS,
    latSplit,
    lonSplit,
    limitPerSection,
    showLocationSearch,
  } = controlDefinition;
  const filteredProps = {
    type: type || 'text',
    value,
    label,
    info,
    readOnly,
    oneLine,
    aggregates,
    height,
    symbols,
    includeWMS,
    latSplit,
    lonSplit,
    limitPerSection,
    showLocationSearch,
  };
  return filteredProps;
};
export const withPageMapProps = (Component: React.FC<TGMapFieldProps>) => {
  return (props: NonRepeatableFieldProps<V2MapComponent>) => {
    const filteredProps = useFilterMapFieldProps(props);
    return <Component {...filteredProps} />;
  };
};

const useFilterStreetViewFieldProps = (props: NonRepeatableFieldProps<V2StreetViewComponent>, type?: string) => {
  const { value, controlDefinition, readOnly } = props;
  const { label, info, oneLine, lat, lon, height } = controlDefinition;
  const filteredProps = {
    type: type || 'text',
    value,
    label,
    info,
    readOnly,
    oneLine,
    lat,
    lon,
    height,
  };
  return filteredProps;
};
export const withPageStreetViewProps = (Component: React.FC<TGStreetViewFieldProps>) => {
  return (props: NonRepeatableFieldProps<V2StreetViewComponent>) => {
    const filteredProps = useFilterStreetViewFieldProps(props);
    return <Component {...filteredProps} />;
  };
};

const PageRootRendererInitiated = initPageRootRenderer({
  ...PageControlProvider,
  aggregateMap: withPageMapProps(TGPageMapField),
  streetView: withPageStreetViewProps(TGPageStreetViewField),
});

interface PagePageProps {
  page: string;
  target?: AssetType;
  noPadding?: boolean;
  fabContainerKey?: string;
  isAssetCard?: boolean;
}

const PagePage: React.FC<PagePageProps> = props => {
  const { target, page, noPadding, fabContainerKey, isAssetCard } = props;
  const { handleFormParentValueChange } = useContext(FormParentValueContext);
  const { functionDefinitions, pageDefinitions, defaultDateTimeFormat } = useConfig();
  const dataMapping = useWorkflowDataMapping(target);

  useEffect(() => {
    if (target) {
      const temp: any = target;
      handleFormParentValueChange(temp?.geom);
    }
    return function cleanup() {
      handleFormParentValueChange(null);
    };
  }, []);

  const { openSnackbar } = useInfoContext();
  useEffect(() => {
    console.log(pageDefinitions && pageDefinitions[page]?.infoPopover, pageDefinitions && pageDefinitions[page]);
    if (pageDefinitions && pageDefinitions[page] && pageDefinitions[page].infoPopover) {
      const isTouch =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 || //@ts-ignore
        navigator.msMaxTouchPoints > 0;
      openSnackbar({
        title: pageDefinitions[page].infoPopover
          ?.replaceAll(/(\.\s+|^\s*)\$__SELECT_METHOD__\$/g, (isTouch ? '$1Long press' : '$1Right click') + ' the map')
          .replaceAll('$__SELECT_METHOD__$', (isTouch ? 'long press' : 'right click') + ' the map'),
        type: 'INFO',
        horizontalPosition: 'center',
        autoHideDuration: null,
        disableClickAway: true,
      });
    }
  }, []);

  return pageDefinitions && pageDefinitions[page] ? (
    <SelectedLocationProvider>
      <ActionsExecutorProvider>
        {!isAssetCard && (
          <PageFABContainer
            containerKey={fabContainerKey}
            // @ts-ignore TODO: Fix typings for FabActions with and without gql transform.
            buttons={pageDefinitions[page].fabActions}
          />
        )}
        <div style={noPadding ? {} : { paddingTop: 15 }}>
          <PageRootRendererInitiated
            dataMappingContext={dataMapping}
            template={pageDefinitions[page]}
            functionDefinitions={functionDefinitions ?? {}}
            defaultDateTimeFormat={defaultDateTimeFormat}
            render={({ renderPage }) => renderPage()}
            disableStreetView={isAssetCard}
          />
        </div>
      </ActionsExecutorProvider>
    </SelectedLocationProvider>
  ) : (
    <></>
  );
};

export default PagePage;
