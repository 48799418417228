import React, { useMemo } from 'react';
import { Dialog, makeStyles, DialogContent, Grid, Typography, Button } from '@material-ui/core';
import ModalDoneCancelTitle from '../ModalDoneCancelTitle';
import * as Yup from 'yup';
import { makeValidate, makeRequired, TextField, Checkboxes } from 'mui-rff';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import SelectorModal from './SelectorModal';
import PasswordField from './PasswordField';

interface AddNewFormBaseAddNewFormField<InitialValueType> {
  name: string;
  label: string;
  initialValue?: InitialValueType;
  allOption?: {
    title: string;
    onClick: (onChange: (value: unknown) => void) => void;
  };
}

export interface AddNewFormCheckboxField extends AddNewFormBaseAddNewFormField<boolean> {
  type: 'checkbox';
  value: string;
}

export interface AddNewFormSelectModalField extends AddNewFormBaseAddNewFormField<boolean> {
  type: 'selectModal';
  values: { label: string; value: string }[];
  multiSelect?: boolean;
  allSelectedMessage: string;
}

export interface AddNewFormTextField extends AddNewFormBaseAddNewFormField<string> {
  type: 'text';
  required: boolean;
}

export type AddNewFormEmailField = Omit<AddNewFormTextField, 'type'> & { type: 'email' };
export type AddNewFormPasswordField = Omit<AddNewFormTextField, 'type'> & { type: 'password' };

export type AddNewFormField =
  | AddNewFormCheckboxField
  | AddNewFormTextField
  | AddNewFormSelectModalField
  | AddNewFormEmailField
  | AddNewFormPasswordField;

export interface AddNewDialogProps {
  title: string;
  onDone?: (value: unknown) => void;
  onCancel?: () => void;
  open: boolean;
  fields: AddNewFormField[];
  message: string;
}

const getYupField = (field: AddNewFormField) => {
  if (field.type === 'text') {
    let result = Yup.string();
    if (field.required) {
      result = result.required();
    }
    return result;
  } else if (field.type === 'password') {
    let result = Yup.string().min(6);
    if (field.required) {
      result = result.required();
    }
    return result;
  } else if (field.type === 'email') {
    let result = Yup.string().email();
    if (field.required) {
      result = result.required();
    }
    return result;
  } else if (field.type === 'checkbox') {
    return Yup.boolean().required();
  } else if (field.type === 'selectModal') {
    return Yup.array();
  }
};

const getLabel = (fields: AddNewFormField[], path: string): string =>
  fields.find(({ name }) => name === path)?.label ?? path;

const AddNewDialog = (props: AddNewDialogProps) => {
  const classes = useStyles();

  const schema = useMemo(
    () =>
      Yup.object().shape(
        props.fields.reduce(
          (result, field) => ({
            ...result,
            [field.name]: getYupField(field),
          }),
          {}
        )
      ),
    [props.fields]
  );

  const validate = makeValidate(schema as any, (error: any) =>
    error.message.replace(error.path, getLabel(props.fields, error.path))
  );
  const required = makeRequired(schema as any);

  const initialValues = useMemo(
    () =>
      props.fields.reduce(
        (result, field) => ({
          ...result,
          [field.name]:
            field.initialValue ?? field.type === 'checkbox' ? false : field.type === 'selectModal' ? [] : undefined,
        }),
        {}
      ),
    [props.fields]
  );

  const controls = useMemo(
    () =>
      props.fields.map((field, index) => {
        const control = (fieldProps: FieldRenderProps<any, HTMLElement>) => {
          if (field.type === 'text' || field.type === 'email') {
            return (
              <TextField
                {...fieldProps}
                placeholder={field.label}
                required={required[field.name]}
                name={field.name}
                className={classes.textField}
              />
            );
          } else if (field.type === 'password') {
            return (
              <PasswordField
                {...fieldProps}
                placeholder={field.label}
                required={required[field.name]}
                name={field.name}
                className={classes.textField}
              />
            );
          } else if (field.type === 'checkbox') {
            return <Checkboxes {...fieldProps} data={{ ...field }} name={field.name} />;
          } else if (field.type === 'selectModal') {
            return (
              <SelectorModal
                onChange={fieldProps.input.onChange}
                value={fieldProps.input.value}
                options={field.values}
                label={field.label}
                multiSelect={field.multiSelect ?? true}
                error={fieldProps.meta.error}
                allSelectedMessage={field.allSelectedMessage}
              />
            );
          }
          return false;
        };
        const allOption = field.allOption;
        return (
          <Grid item key={index} xs={12}>
            <Field name={field.name}>
              {(fieldProps: FieldRenderProps<any, HTMLElement, any>) => (
                <Grid container>
                  <Grid item xs={12} className={classes.control}>
                    {control(fieldProps)}
                  </Grid>
                  {allOption && (
                    <Grid item xs={12} className={classes.selectAllContainer}>
                      <Typography className={classes.selectAllTypography}>
                        OR{' '}
                        <Button
                          onClick={() => allOption.onClick(fieldProps.input.onChange)}
                          className={classes.selectAllButton}
                        >
                          {allOption.title}
                        </Button>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Field>
          </Grid>
        );
      }),
    [props.fields, required, classes]
  );

  return (
    <Form
      validate={validate}
      onSubmit={data => {
        props.onDone && props.onDone(data);
      }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <Dialog open={props.open} onClose={props.onCancel} classes={{ paper: classes.paper }}>
          <ModalDoneCancelTitle title={props.title} onDone={handleSubmit} onCancel={props.onCancel} />
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.headerText}>{props.message}</Typography>
              </Grid>
              {controls}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    />
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 650,
    paddingBottom: 60,
  },
  headerText: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 20,
    height: 17,
    lineHeight: 1,
  },
  textField: {
    width: 330,
  },
  control: {
    marginTop: 12.5,
    marginBottom: 17.5,
    height: 25,
  },
  selectorAll: {
    height: 60,
  },
  selectAllContainer: {},
  selectAllTypography: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    display: 'flex',
    alignItems: 'center',
  },
  selectAllButton: {
    color: theme.palette.primary.main,
  },
}));

export default AddNewDialog;
